
import CameraStatusIcon from "@evercam/shared/components/CameraStatusIcon"

export default {
  name: "CameraRessource",
  components: {
    CameraStatusIcon,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
}
